import { render, staticRenderFns } from "./TechDiagnosis.vue?vue&type=template&id=e821d51a&scoped=true"
import script from "./TechDiagnosis.vue?vue&type=script&lang=js"
export * from "./TechDiagnosis.vue?vue&type=script&lang=js"
import style0 from "./TechDiagnosis.vue?vue&type=style&index=0&id=e821d51a&prod&scoped=true&lang=css"
import style1 from "./TechDiagnosis.vue?vue&type=style&index=1&id=e821d51a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e821d51a",
  null
  
)

export default component.exports